import styled from "styled-components"
import { space } from "styled-system"
import { Link } from "gatsby"

export const Container = styled.article`
  ${space}
  grid-column: span 4;
`
Container.defaultProps = {
  mb: ["y.mobile.3.0", "y.desktop.3.0"],
}

export const Section = styled.section`
  ${space}
`

Section.defaultProps = {
  my: ["y.mobile.0.5", "y.desktop.0.5"],
}

export const ReadMore = styled(Link)`
  display: flex;
`
