import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }
`

export const Image = styled(GatsbyImage)`
  height: 100%;
`
