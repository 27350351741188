import styled from "styled-components"
import { space } from "styled-system"

export const Container = styled.article`
  ${space}
  grid-column: span 4;
`
Container.defaultProps = {
  mb: ["y.mobile.3.0", "y.desktop.3.0"],
}
