import React from "react"
import { Container } from "./listing.styles"
import PostPreview from "../components/post-preview"
import FilmPreview from "../components/film-preview"

export default ({ nodes }) => {
  if (!nodes) return null

  return (
    <Container>
      {nodes.map((node) => {
        switch (node.type) {
          case "post":
            return <PostPreview key={node.id} post={node} />

          case "film":
            return <FilmPreview key={node.id} film={node} />

          default:
            return
        }
      })}
    </Container>
  )
}
