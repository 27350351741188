import React from "react"
import { getImage } from "gatsby-plugin-image"
import { Container, Image } from "./background-image.styles"

export default ({ image }) => {
  return (
    <Container>
      <Image
        image={getImage(image)}
        alt={image.alt ?? "Background Image"}
        objectFit="cover"
        objectPosition="top center"
      />
    </Container>
  )
}
