import React from "react"
import { Link } from "gatsby"
import { Inner, Content } from "./teaser.styles"
import SectionHeader from "./section-header"
import BackgroundImage from "./background-image"

import Text from "./text"

export default ({ workshop }) => {
  if (!workshop) return null
  const { subtitle, image } = workshop

  return (
    <>
      <SectionHeader title="Workshops" cta="More Info" to="/workshops" />
      <Inner as={Link} to="/workshops">
        <Content>
          <Text.H2 textAlign="center" variant="dark" mt={0} mb={0}>
            {subtitle.richText.map(({ text }, index) => (
              <React.Fragment key={`subtitle-${index}`}>
                {text}
                <br />
              </React.Fragment>
            ))}
          </Text.H2>
        </Content>
        <BackgroundImage image={image} />
      </Inner>
    </>
  )
}
