import styled from "styled-components"
import { compose, grid, space, layout, flexbox } from "styled-system"

export const Container = styled.div(compose(grid, space, layout, flexbox), {
  display: "grid",
})

Container.defaultProps = {
  gridColumn: "main-start / main-end",
  gridTemplateColumns: ["repeat(4, 1fr)", "repeat(8,1fr)", "repeat(12, 1fr)"],
  gridColumnGap: ["1.6rem", "2.4rem", "2.4rem"],
}
