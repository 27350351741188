import styled, { keyframes } from "styled-components"
import { position } from "styled-system"
import ArrowIcon from "./icons/arrow-icon"

const bounce = keyframes`
  0% {opacity: 1; transform: translateY(0px);}
  50%{opacity: 0; transform:translateY(48px);}
  51%{opacity: 0; transform:translateY(-48px);}
  100% {opacity: 1; transform: translateY(0px);}
`
const bounce2 = keyframes`
  0% {opacity: 0; transform: translateY(-48px);}
  50%{opacity: 1; transform:translateY(0px);}
  51%{opacity: 1; transform:translateY(0px);}
  100% {opacity: 0; transform: translateY(48px);}
`

export const ArrowOne = styled.div`
  position: absolute;
  animation-name: ${bounce};
`

export const ArrowTwo = styled.div`
  position: absolute;
  animation-name: ${bounce2};
`

export const Container = styled.div`
  ${position}
  position: absolute;
  height: 4.8rem;
  width: 4.8rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  cursor: pointer;

  &:hover ${ArrowOne}, &:hover ${ArrowTwo} {
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`
Container.defaultProps = {
  bottom: ["y.mobile.3.0", "y.desktop.3.0"],
}

export const Arrow = styled(ArrowIcon)``
Arrow.defaultProps = {
  rotate: 90,
  size: 48,
  color: "white",
}
