import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { linkResolver } from "../utils/linkResolver"

import { Container } from "./film-preview.styles"

export default ({ film }) => {
  const image = getImage(film.data.cover)
  return (
    <Container>
      <Link to={linkResolver(film)}>
        {image && <GatsbyImage image={image} alt={film.data.cover.alt ?? ""} />}
      </Link>
    </Container>
  )
}
