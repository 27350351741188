import React from "react"
import { TweenMax, Power2 } from "gsap"

import { Container, ArrowOne, ArrowTwo, Arrow } from "./scroll-to.styles"
const { ScrollToPlugin } = require("gsap/umd/ScrollToPlugin")

export default ({ bottom }) => {
  const scrollTo = () => {
    TweenMax.to(window, 0.4, {
      scrollTo: { y: window.innerHeight, autoKill: false },
      ease: Power2.easeOut,
    })
  }

  return (
    <Container bottom={bottom} onClick={() => scrollTo()}>
      <ArrowOne>
        <Arrow />
      </ArrowOne>
      <ArrowTwo>
        <Arrow />
      </ArrowTwo>
    </Container>
  )
}
