import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { DateTime } from "luxon"
import get from "lodash.get"

import { linkResolver } from "../utils/linkResolver"
import { Container, Section, ReadMore } from "./post-preview.styles"
import Text from "./text"
import DashIcon from "./mdash"

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  // Find the first text slice of post's body
  let firstTextSlice = post.data.body.find(
    (slice) => slice.slice_type === "text"
  )
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 135
    let text = RichText.asText(firstTextSlice.primary.text.richText)
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <Text.Small
          as="p"
          color="black.2"
          lineHeight={["mobile.1.0", "desktop.1.0"]}
        >
          {limitedText.substring(0, limitedText.lastIndexOf(" ")) + "..."}
        </Text.Small>
      )
    } else {
      // If it's shorter than the limit, just show it normally
      return (
        <Text.Small
          as="p"
          color="black.2"
          lineHeight={["mobile.1.0", "desktop.1.0"]}
        >
          {text}
        </Text.Small>
      )
    }
  } else {
    // If there are no slices of type 'text', return nothing
    return null
  }
}

export default ({ post }) => {
  const date = DateTime.fromISO(post.data.date).toLocaleString(
    DateTime.DATE_MED
  )
  const image = getImage(post.data.image)
  const category = get(post, "data.category.document")

  return (
    <Container>
      <header>
        {image && <GatsbyImage image={image} alt={image.alt ?? ""} />}
        <Text.Label
          as={Link}
          to={linkResolver(category)}
          mt={["y.mobile.0.5", "y.desktop.0.5"]}
        >
          {category.data.name}
        </Text.Label>
        <Link to={linkResolver(post)}>
          <Text.H3
            mt={["y.mobile.0.0", "y.desktop.0.0"]}
            mb={["y.mobile.0.0", "y.desktop.0.0"]}
          >
            {post.data.title}
          </Text.H3>
        </Link>
        <Text.Small as="time" color="black.6">
          Published on {date}
        </Text.Small>
      </header>

      <Section>{firstParagraph(post)}</Section>

      <ReadMore to={linkResolver(post)}>
        <DashIcon />
        <Text.Small ml="x.1" color="black.6">
          Read More
        </Text.Small>
      </ReadMore>
    </Container>
  )
}
