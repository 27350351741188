import React from "react"

export default () => (
  <svg
    width="24px"
    height="28px"
    viewBox="0 0 48 56"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon</title>
    <desc>Created with Sketch.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#999999" x="0" y="27" width="48" height="2"></rect>
    </g>
  </svg>
)
