import styled from "styled-components"
import { space } from "styled-system"

export const Container = styled.div`
  position: relative;
  display: flex;
  min-height: 56.8rem;
  height: 100vh;
  justify-content: center;
  ${space}
`

Container.defaultProps = {
  px: "x.2",
}

export const Content = styled.div`
  position: relative;
  z-index: 10;
  max-width: 80rem;
  align-self: center;
`
