import styled from "styled-components"
import { compose, grid, space, layout } from "styled-system"
import { GatsbyImage } from "gatsby-plugin-image"
// import Grid from "./grid"

export const Container = styled.div(compose(grid, space, layout), {
  display: "grid",
})

Container.defaultProps = {
  gridColumn: "main-start / main-end",
  gridTemplateColumns: "1fr 1fr",
  gridTemplateAreas: [
    `"left left" "right right"`,
    `"left left" "right right"`,
    `"left right"`,
  ],
  gridColumnGap: ["1.6rem", "2.4rem", "2.4rem"],
}

export const Image = styled(GatsbyImage)`
  grid-area: left;
`

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  grid-area: right;
`
