import React from "react"

import { Container, Content } from "./landing-hero.styles"
import BackgroundImage from "./background-image"
import Text from "./text"
import ScrollTo from "./scroll-to"

export default ({ title, image }) => {
  return (
    <Container>
      <Content>
        <Text.H0
          textAlign="center"
          mb={["y.mobile.0.5", "y.desktop.0.5"]}
          variant="dark"
        >
          {title.text}
        </Text.H0>
      </Content>
      <BackgroundImage image={image} />
      <ScrollTo />
    </Container>
  )
}
