import styled from "styled-components"
import { space } from "styled-system"

import Grid from "./grid"

export const Inner = styled(Grid.Item)`
  position: relative;
  padding: 25% 0;
`
Inner.defaultProps = {
  gridColumn: "main-start / main-end",
}

export const Content = styled.div`
  position: relative;
  z-index: 10;
`
