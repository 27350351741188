import React from "react"
import { Container, Title } from "../components/section-header.styles"
import CTA from "../components/cta-link"

export default ({ title, cta, to }) => (
  <Container>
    <Title>{title}</Title>
    <CTA to={to}>{cta}</CTA>
  </Container>
)
