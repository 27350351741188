import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { DateTime } from "luxon"
import get from "lodash.get"

import { Container, Image, Content } from "./featured.styles"
import SectionHeader from "./section-header"
import Text from "./text"
import { linkResolver } from "../utils/linkResolver"
import getExcerptOfBody from "../utils/getExcerpt"

const Featured = ({ post }) => {
  if (!post?.data) return null
  const date = DateTime.fromISO(post.data.date).toLocaleString(
    DateTime.DATE_MED
  )
  const category = get(post, "data.category.document")

  return (
    <>
      <SectionHeader
        title="Featured"
        cta="Read Story"
        to={linkResolver(post)}
      />
      <Container>
        <Image
          image={getImage(post.data.image)}
          alt={post.data.image.alt ?? ""}
        />
        <Content>
          <Text.Label
            as={Link}
            to={linkResolver(category)}
            mt={["y.mobile.0.5", "y.desktop.0.5"]}
          >
            {category.data.name}
          </Text.Label>
          <Link to={linkResolver(post)}>
            <Text.H3
              mt={["y.mobile.0.0", "y.desktop.0.0"]}
              mb={["y.mobile.0.0", "y.desktop.0.0"]}
            >
              {post.data.title}
            </Text.H3>
          </Link>
          <Text.Small as="time" color="black.6">
            Published on {date}
          </Text.Small>
          <Text.P>{getExcerptOfBody(post.data.body)}</Text.P>
        </Content>
      </Container>
    </>
  )
}

export default Featured
