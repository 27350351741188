import React from "react"
import SectionHeader from "./section-header"
import Listing from "./listing"

export default ({ films }) => {
  return (
    <>
      <SectionHeader title="Latest Films" cta="See All" to="/films" />
      <Listing nodes={films} />
    </>
  )
}
