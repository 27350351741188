import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import styled from "styled-components"
import { space, grid } from "styled-system"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/landing-hero"
import Slices from "../components/slices"
import Grid from "../components/grid"
import Featured from "../components/featured"
import Teaser from "../components/teaser"
import LatestFilms from "../components/latest-films"
import RecentNews from "../components/recent-news"

export const query = graphql`
  query Landing {
    site {
      siteMetadata {
        title
      }
    }
    prismicLanding {
      data {
        title {
          text
          richText
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicLandingDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
                text
              }
            }
          }
          ... on PrismicLandingDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                richText
                text
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
          ... on PrismicLandingDataBodyGallery {
            id
            slice_type
            slice_label
            items {
              caption {
                richText
                text
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
          ... on PrismicLandingDataBodyLink {
            id
            slice_type
            slice_label
            primary {
              linkText: text
              link {
                type
                uid
              }
            }
          }
        }
        featured {
          document {
            ... on PrismicPost {
              id
              uid
              type
              data {
                title
                image {
                  gatsbyImageData
                }
                category {
                  document {
                    ... on PrismicCategory {
                      id
                      uid
                      type
                      data {
                        name
                      }
                    }
                  }
                }
                date
                body {
                  ... on PrismicPostDataBodyText {
                    id
                    slice_type
                    primary {
                      text {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicWorkshop {
      id
      uid
      type
      data {
        subtitle {
          richText
          text
        }
        image {
          alt
          copyright
          gatsbyImageData(layout: CONSTRAINED, width: 1032)
        }
      }
    }
    allPrismicFilm(sort: { order: DESC, fields: data___date }, limit: 3) {
      nodes {
        id
        uid
        type
        data {
          title
          date
          cover {
            alt
            copyright
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 476)
          }
        }
      }
    }
    allPrismicPost(limit: 3, sort: { order: DESC, fields: data___date }) {
      nodes {
        id
        uid
        type
        data {
          image {
            alt
            copyright
            gatsbyImageData(layout: CONSTRAINED, width: 328, height: 210)
          }
          category {
            document {
              ... on PrismicCategory {
                id
                uid
                type
                data {
                  name
                }
              }
            }
          }
          title
          date
          body {
            ... on PrismicPostDataBodyText {
              slice_label
              slice_type
              primary {
                text {
                  text
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`

const Article = styled(Grid)(space, grid)
Article.defaultProps = {
  mt: ["y.mobile.0.0", "y.desktop.0.0", "y.desktop.2.0"],
  gridTemplateColumns: [
    "[full-start gallery-start] auto [main-start breadcrumbs-start text-start] repeat(4, 1fr) [text-end breadcrumbs-end main-end] auto [gallery-end full-end]",
    "[full-start gallery-start] auto [main-start breadcrumbs-start] 1fr [text-start] repeat(6, 1fr) [text-end] 1fr [breadcrumbs-end main-end] auto [gallery-end full-end]",
    "[full-start gallery-start] auto [main-start subnav-start breadcrumbs-start] repeat(3,1fr) [subnav-end text-start] repeat(6, 1fr)  [text-end] repeat(3, 1fr)  [breadcrumbs-end main-end] auto [gallery-end full-end]",
    "[full-start gallery-start] auto [main-start subnav-start breadcrumbs-start] 6.4rem 6.4rem 6.4rem [subnav-end text-start] 6.4rem 6.4rem 6.4rem 6.4rem 6.4rem 6.4rem  [text-end] 6.4rem 6.4rem 6.4rem  [breadcrumbs-end main-end] auto [gallery-end full-end]",
  ],
}

export default function HomePage({ location, data }) {
  const page = get(data, `prismicLanding.data`)
  const featured = get(data, `prismicLanding.data.featured.document`)
  const workshop = get(data, `prismicWorkshop.data`)
  const posts = get(data, `allPrismicPost.nodes`)
  const films = get(data, `allPrismicFilm.nodes`)

  if (!page) return null

  return (
    <Layout location={location} variant="dark">
      <SEO title="Home" description={page.title.text} image={page.image} />
      <Hero title={page.title} image={page.image} />
      <Article page>
        <Slices slices={page.body} page />
        <Featured post={featured} />
        <Teaser workshop={workshop} />
        <LatestFilms films={films} />
        <RecentNews posts={posts} />
      </Article>
    </Layout>
  )
}
