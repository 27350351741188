import { RichText } from "prismic-reactjs"

export default function getExcerptOfBody(body) {
  let firstTextSlice = body.find((slice) => slice.slice_type === "text")
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 135
    let text = RichText.asText(firstTextSlice.primary.text.richText)
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return limitedText.substring(0, limitedText.lastIndexOf(" ")) + "..."
    } else {
      // If it's shorter than the limit, just show it normally
      return text
    }
  } else {
    // If there are no slices of type 'text', return nothing
    return null
  }
}
