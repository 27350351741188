import React from "react"
import SectionHeader from "./section-header"
import Listing from "./listing"

export default ({ posts }) => {
  return (
    <>
      <SectionHeader title="Recent News" cta="See More" to="/news" />
      <Listing nodes={posts} />
    </>
  )
}
