import styled from "styled-components"
import Grid from "../components/grid"
import Text from "../components/text"

export const Container = styled(Grid.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
Container.defaultProps = {
  mt: ["y.mobile.10.0", "y.dekstop.10.0"],
  mb: ["y.mobile.1.0", "y.desktop.1.0"],
  gridColumn: "main-start / main-end",
}

export const Title = styled(Text.H2)``
Title.defaultProps = {
  mt: 0,
  mb: 0,
}
